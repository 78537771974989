// ** typedjson imports
import { jsonObject, jsonMember, jsonArrayMember, AnyT } from 'typedjson'

@jsonObject
export class ContactCertificationModel {
    @jsonMember(String)
    end_date: string | null

    @jsonMember(String)
    name: string | null

    @jsonMember(String)
    organization: string | null

    @jsonMember(String)
    start_date: string | null
}

@jsonObject
class ContactExperienceCompanyLocationModel {
    @jsonMember(String)
    address_line_2: string | null

    @jsonMember(String)
    continent: string | null

    @jsonMember(String)
    country: string | null

    @jsonMember(String)
    geo: string | null

    @jsonMember(String)
    locality: string | null

    @jsonMember(String)
    metro: string | null

    @jsonMember(String)
    name: string | null

    @jsonMember(String)
    postal_code: string | null

    @jsonMember(String)
    region: string | null

    @jsonMember(String)
    street_address: string | null
}

@jsonObject
class ContactExperienceCompanyModel {
    @jsonMember(String)
    facebook_url: string | null

    @jsonMember(String)
    founded: string | null

    @jsonMember(String)
    id: string | null

    @jsonMember(String)
    job_company_id_mongo: string

    @jsonMember(String)
    industry: string | null

    @jsonMember(String)
    linkedin_id: string | null

    @jsonMember(String)
    linkedin_url: string | null

    @jsonMember(ContactExperienceCompanyLocationModel)
    location: ContactExperienceCompanyLocationModel

    @jsonMember(String)
    name: string | null

    @jsonMember(String)
    size: string | null

    @jsonMember(String)
    twitter_url: string | null

    @jsonMember(String)
    website: string | null
}

@jsonObject
class ContactEducationSchoolLocationModel {
    @jsonMember(String)
    continent: string | null

    @jsonMember(String)
    country: string | null

    @jsonMember(String)
    locality: string | null

    @jsonMember(String)
    name: string | null

    @jsonMember(String)
    region: string | null
}

@jsonObject
class ContactEducationSchoolModel {
    @jsonMember(String)
    domain: string | null

    @jsonMember(String)
    facebook_url: string | null

    @jsonMember(String)
    id: string | null

    @jsonMember(String)
    linkedin_id: string | null

    @jsonMember(String)
    linkedin_url: string | null

    @jsonMember(ContactEducationSchoolLocationModel)
    location: ContactEducationSchoolLocationModel

    @jsonMember(String)
    name: string | null

    @jsonMember(String)
    twitter_url: string | null

    @jsonMember(String)
    type: string | null

    @jsonMember(String)
    website: string | null

    @jsonMember(String)
    university_id_mongo: string | null

    @jsonMember(String)
    job_company_id_mongo: string | null
}

@jsonObject
export class ContactEducationModel {
    @jsonArrayMember(String)
    degrees: Array<string>

    @jsonMember(String)
    end_date: string | null

    @jsonMember(String)
    gpa: string | null

    @jsonArrayMember(String)
    majors: Array<string>

    @jsonArrayMember(String)
    minors: Array<string>

    @jsonMember(ContactEducationSchoolModel)
    school: ContactEducationSchoolModel

    @jsonMember(String)
    start_date: string | null

    @jsonMember(String)
    summary: string | null
}

@jsonObject
class ContactExperienceTitleModel {
    @jsonArrayMember(String)
    levels: Array<string>

    @jsonMember(String)
    name: string | null

    @jsonMember(String)
    role: string | null

    @jsonMember(String)
    sub_role: string | null
}

@jsonObject
export class ContactExperienceModel {
    @jsonMember(ContactExperienceCompanyModel)
    company: ContactExperienceCompanyModel

    @jsonMember(String)
    start_date: string | null

    @jsonMember(String)
    summary: string

    @jsonMember(String)
    end_date: string | null

    @jsonMember(Boolean)
    is_primary: boolean

    @jsonArrayMember(String)
    location_names: Array<string>

    @jsonMember(ContactExperienceTitleModel)
    title: ContactExperienceTitleModel
}

@jsonObject
class ContactProfileModel {
    @jsonMember(String)
    id: string | null

    @jsonMember(String)
    network: string | null

    @jsonMember(String)
    url: string | null

    @jsonMember(String)
    username: string | null
}

@jsonObject
class ContactVersionStatusModel {
    @jsonArrayMember(AnyT)
    contains: Array<unknown>

    @jsonMember(String)
    current_version: string | null

    @jsonMember(String)
    previous_version: string | null

    @jsonMember(String)
    status: string | null
}

@jsonObject
export class ContactModel {
    @jsonMember(String)
    _id: string

    @jsonMember(String)
    full_name: string

    @jsonMember(String)
    first_name: string

    @jsonMember(String)
    last_name: string

    @jsonMember(String)
    job_company_website: string | null

    @jsonMember(String)
    job_company_twitter_url: string | null

    @jsonMember(String)
    birth_date: string | null

    @jsonMember(String)
    birth_year: string | null

    @jsonMember(String)
    job_company_id_mongo: string | null

    @jsonArrayMember(ContactCertificationModel)
    certifications: Array<ContactCertificationModel>

    @jsonArrayMember(String)
    countries: Array<string>

    @jsonArrayMember(ContactEducationModel)
    education: Array<ContactEducationModel>

    @jsonMember(String)
    email: string | null

    @jsonMember(String)
    work_email: string

    @jsonMember(String)
    current_work_email: string | null

    @jsonMember(Boolean)
    has_personal_email: boolean

    @jsonMember(String)
    current_personal_email: string | null

    @jsonArrayMember(ContactExperienceModel)
    experience: Array<ContactExperienceModel>

    @jsonMember(String)
    facebook_id: string | null

    @jsonMember(String)
    facebook_url: string | null

    @jsonMember(String)
    facebook_username: string | null

    @jsonMember(String)
    gender: string | null

    @jsonMember(String)
    github_url: string | null

    @jsonMember(String)
    github_username: string | null

    @jsonMember(Boolean)
    has_email: boolean

    @jsonMember(Boolean)
    has_work_email: boolean

    @jsonMember(Boolean)
    has_keyword: boolean

    @jsonMember(Boolean)
    has_phone: boolean

    @jsonMember(String)
    industry: string | null

    @jsonMember(String)
    inferred_salary: string | null

    @jsonMember(String)
    inferred_years_experience: string | null

    @jsonArrayMember(String)
    interests: Array<string>

    @jsonMember(String)
    job_company_facebook_url: string | null

    @jsonMember(String)
    job_company_founded: string | null

    @jsonMember(String)
    job_company_id: string | null

    @jsonMember(String)
    job_company_industry: string | null

    @jsonMember(String)
    job_company_linkedin_id: string | null

    @jsonMember(String)
    job_company_linkedin_url: string | null

    @jsonMember(String)
    job_company_location_address_line_2: string | null

    @jsonMember(String)
    job_company_location_continent: string | null

    @jsonMember(String)
    job_company_location_country: string | null

    @jsonMember(String)
    company_location_city: string | null

    @jsonMember(String)
    company_location_country: string | null

    @jsonMember(String)
    company_location_state: string | null

    @jsonMember(String)
    contact_location_city: string | null

    @jsonMember(String)
    contact_location_country: string | null

    @jsonMember(String)
    contact_location_state: string | null

    @jsonMember(String)
    job_company_location_geo: string | null

    @jsonMember(String)
    job_company_location_locality: string | null

    @jsonMember(String)
    job_company_location_metro: string | null

    @jsonMember(String)
    job_company_location_name: string | null

    @jsonMember(String)
    job_company_location_postal_code: string | null

    @jsonMember(String)
    job_company_location_region: string | null

    @jsonMember(String)
    job_company_location_street_address: string | null

    @jsonMember(String)
    job_company_name: string | null

    @jsonMember(String)
    job_company_size: string | null

    @jsonMember(String)
    job_last_updated: string | null

    @jsonMember(String)
    job_start_date: string | null

    @jsonMember(String)
    job_summary: string | null

    @jsonMember(String)
    job_title: string | null

    @jsonMember(String)
    job_title_levels: string

    @jsonMember(String)
    job_title_role: string | null

    @jsonMember(String)
    job_title_sub_role: string | null

    @jsonArrayMember(String)
    languages: Array<string>

    @jsonMember(Number)
    linkedin_connections: number

    @jsonMember(String)
    linkedin_id: string | null

    @jsonMember(String)
    linkedin_url: string | null

    @jsonMember(String)
    linkedin_username: string | null

    @jsonMember(String)
    location_address_line_2: string | null

    @jsonMember(String)
    location_continent: string | null

    @jsonMember(String)
    location_country: string | null

    @jsonMember(String)
    location_geo: string | null

    @jsonMember(String)
    location_last_updated: string | null

    @jsonMember(String)
    location_locality: string | null

    @jsonMember(String)
    location_metro: string | null

    @jsonMember(String)
    location_name: string | null

    @jsonArrayMember(String)
    location_names: Array<string>

    @jsonMember(String)
    location_postal_code: string | null

    @jsonMember(String)
    location_region: string | null

    @jsonMember(String)
    location_street_address: string | null

    @jsonMember(String)
    middle_initial: string | null

    @jsonMember(String)
    middle_name: string | null

    @jsonMember(String)
    mobile_phone: string | null

    @jsonArrayMember(String)
    phone_numbers: Array<string>

    @jsonMember(Boolean)
    product_tag: boolean

    @jsonArrayMember(ContactProfileModel)
    profiles: Array<ContactProfileModel>

    @jsonArrayMember(String)
    regions: Array<string>

    @jsonArrayMember(String)
    skills: Array<string>

    @jsonArrayMember(ContactExperienceCompanyLocationModel)
    street_addresses: Array<ContactExperienceCompanyLocationModel>

    @jsonMember(String)
    summary: string | null

    @jsonArrayMember(String)
    summary_keywords: Array<string>

    @jsonMember(String)
    twitter_url: string | null

    @jsonMember(String)
    twitter_username: string | null

    @jsonArrayMember(ContactVersionStatusModel)
    version_status: Array<ContactVersionStatusModel>

    @jsonMember(Boolean)
    exists: boolean
}
