// ** typedjson imports
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class JobTitleRoleModel {
    @jsonMember(String)
    name: string

    @jsonMember(String)
    title: string
}
