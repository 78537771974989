// ** typedjson imports
import { jsonObject, jsonMember, jsonArrayMember, AnyT } from 'typedjson'

// ** types imports
import { Integration } from 'src/types'

export type ServiceMode = 'single' | 'multi'
export type ServiceIntegrationType = 'contact' | 'company' | 'both'

@jsonObject
export class ServiceModel {
    @jsonMember(Number)
    id: number

    @jsonMember(String)
    service_name: string

    @jsonArrayMember(String)
    input: Array<string> | Array<Array<string>>

    @jsonMember(String)
    output: string

    @jsonMember(String)
    service_code: string

    @jsonMember(AnyT)
    icon: any

    @jsonMember(String)
    mode: ServiceMode

    @jsonMember(String)
    video_link: string

    @jsonMember(String)
    tag: string

    @jsonMember(String)
    tag_color: string

    @jsonArrayMember(String)
    available_integrations: Array<Integration>

    @jsonMember(String)
    integration_type: ServiceIntegrationType | null
}
