// ** react imports
import { PropsWithChildren, useEffect, useState } from 'react'

// ** react router imports
import { Navigate, useLocation } from 'react-router-dom'

// ** redux & store imports
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

// ** context imports
import { AbilityContext } from 'src/layouts/components/acl/Can'

// ** third party imports
import { RawRule } from '@casl/ability'

// ** config import
import { type ACLObj, buildAbilityFor, type AppAbility } from 'src/configs/acl'

// ** models imports
import { UserRoleType } from 'src/models'

interface AclGuardProps {
    aclAbilities: ACLObj
}

const freeRoutes = ['/', '/error/401', '/error/403', '/error/404', '/error/500']

const AclGuard = ({ aclAbilities, children }: PropsWithChildren<AclGuardProps>) => {
    const [ability, setAbility] = useState<AppAbility | undefined>(undefined)

    // ** hooks
    const { user } = useSelector((state: RootState) => state.auth.auth)
    const { pathname } = useLocation()

    useEffect(() => {
        setAbility(undefined)
    }, [pathname])

    // If guestGuard is true and user is not logged in or its an error page, render the page without checking access
    if (freeRoutes.includes(pathname)) {
        return <Navigate to={pathname} />
    }

    // User is logged in, build ability for the user based on his role
    if (user && !ability) {
        const userRole = user.role == 'b2b' ? UserRoleType.B2B : UserRoleType.INDUSTRIAL

        const dynamicRules: Array<RawRule> = user.permissions.map((item) => ({
            action: item.engine == 'enrichment' ? 'page-view' : ['page-view', 'write'],
            subject: item.permission,
            fields: item.properties.length > 0 ? item.properties : ['any']
        }))

        setAbility(buildAbilityFor(userRole, dynamicRules))
    }

    // Check the access of current user and render pages
    if (ability && ability.can(aclAbilities.action, aclAbilities.subject, aclAbilities?.filed)) {
        return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
    }

    if (aclAbilities.action == 'menu') {
        return
    }

    // Render Not Authorized component if the current user has limited access
    return <Navigate to='/error/403' />
}

export default AclGuard
