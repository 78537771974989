// ** mui imports
import Box, { type BoxProps } from '@mui/material/Box'

// ** custom components imports
import Image from 'src/components/Image'

// ** third party imports
import { Player } from '@lottiefiles/react-lottie-player'

type CompanyLogoProps = {
    companyLogo: string
    size?: number
    onlyCompanyLogo?: boolean
} & BoxProps
export default function CompanyLogo({ companyLogo, size = 60, onlyCompanyLogo = false, ...rest }: CompanyLogoProps) {
    return (
        <Box display='flex' justifyContent='center' alignItems='center' {...rest}>
            {!onlyCompanyLogo && (
                <>
                    <Image variant='circular' src='/images/favicon.png' alt='logo' sx={{ width: size, height: size }} />
                    <Player
                        autoplay
                        loop
                        src='/images/lordicon/handshake.json'
                        style={{ height: `${size}px`, width: `${size}px` }}
                    ></Player>
                </>
            )}

            <Image
                src={companyLogo}
                variant='circular'
                alt={companyLogo}
                sx={{ width: size, height: 'auto', borderRadius: '9999px' }}
            />
        </Box>
    )
}
