// ** typedjson imports
import { jsonObject, jsonMember } from 'typedjson'

@jsonObject
export class BulkModel {
    @jsonMember(Number)
    id: number

    @jsonMember(Number)
    time: string

    @jsonMember(Number)
    name: string

    @jsonMember(Number)
    service: string

    @jsonMember(Number)
    count: number

    @jsonMember(Number)
    passed: number
}
