// ** Icon Imports
import { Icon, IconProps as IconifyProps } from '@iconify/react'

export interface IconProps extends IconifyProps {}

const IconifyIcon = ({ icon, ...rest }: IconProps) => {
    return <Icon icon={icon} fontSize='1.5rem' {...rest} />
}

export default IconifyIcon
