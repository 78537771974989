// ** typedjson imports
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class PlanItemModel {
    @jsonMember(Number)
    id: number

    @jsonMember(String)
    name: string

    @jsonMember(String)
    sub_items: string | null

    @jsonMember(Date)
    created_at: Date | null

    @jsonMember(Date)
    updated_at: Date | null
}
