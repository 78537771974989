// ** react imports
import { memo } from 'react'

// ** context imports
import { useSettings } from 'src/@core/hooks/useSettings'

// ** mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import ClickAwayListener from '@mui/material/ClickAwayListener'

// ** custom components imports
import Button from 'src/components/Button'
import Icon from 'src/@core/components/icon'
import Image from 'src/components/Image'

// ** third party imports
import { useIntercom } from 'react-use-intercom'

const IntercomLauncher = () => {
    // ** hooks
    const { settings, saveSettings } = useSettings()
    const { hide, isOpen: isIntercomPopupOpen } = useIntercom()

    // ** methods
    const handleShowChatPopupIcon = () => {
        saveSettings({ chatPopupOpen: true })
    }

    const handleHideChatPopupIcon = () => {
        if (isIntercomPopupOpen) hide()

        saveSettings({ chatPopupOpen: false })
    }

    const handleUpdatePopup = () => {
        hide()
    }

    return (
        <ClickAwayListener onClickAway={handleUpdatePopup}>
            <Box
                id='intercom-chat-popup-container'
                sx={{
                    position: 'fixed',
                    displayPrint: 'none',
                    zIndex: 999,
                    ...(settings.chatPopupOpen && {
                        bottom: 70,
                        right: 10
                    }),
                    ...(!settings.chatPopupOpen && {
                        bottom: 0,
                        right: 5
                    })
                }}
            >
                {settings.chatPopupOpen ? (
                    <Stack alignItems='center'>
                        <IconButton id='intercom-chat-popup' disableRipple>
                            {isIntercomPopupOpen ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 50,
                                        height: 50,
                                        borderRadius: 9999,
                                        bgcolor: '#1E184E'
                                    }}
                                >
                                    <Icon icon='ci:caret-down-md' color='#FFF' />
                                </Box>
                            ) : (
                                <Image src='/images/favicon.png' alt='popup' sx={{ width: 50, height: 50 }} />
                            )}
                        </IconButton>
                        <Button
                            size='small'
                            color='info'
                            onClick={handleHideChatPopupIcon}
                            sx={{ py: '0.5px!important', px: '12px!important' }}
                        >
                            hide
                        </Button>
                    </Stack>
                ) : (
                    <Button
                        size='small'
                        color='info'
                        onClick={handleShowChatPopupIcon}
                        sx={{
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            py: '0.5px!important',
                            px: '10px!important'
                        }}
                    >
                        support
                    </Button>
                )}
            </Box>
        </ClickAwayListener>
    )
}

export default memo(IntercomLauncher)
