// ** services imports
import BaseApiService from 'src/services/base/base.service'

// ** third party imports
import axios, { AxiosHeaders } from 'axios'

// ** models imports
import { MapboxGEOCodeModel } from 'src/models'

// ** types imports
import {
    CompaniesAPIResponse,
    CountriesAPIResponse,
    CreditAPIResponseModel,
    DomainsAPIResponse,
    IndustriesAPIResponse,
    ItemListsAPIResponse,
    JobTitleLevelsAPIResponse,
    JobTitleRolesAPIResponse,
    ListsAPIResponse,
    type NewListParams,
    type ListOfListsApiParams,
    type AddItemToListApiParams,
    type DeleteListItemApiParams,
    type LocationCoordinatesApiParams,
    type CompanyEmailParams,
    type CompanyLogoParams,
    LocationsApiResponse,
    CompanyAutocompleteType,
    NewEventParams
} from './types'
import { ApiRequestOptions } from 'src/services/base/types'

type AxiosOptionsAsParam = Omit<ApiRequestOptions, 'headers'>

export default class CommonService extends BaseApiService {
    private readonly commonPrefix = this.getBaseURL.concat('common/')
    private readonly commonPrefixV2 = this.getBaseURLV2.concat('common/')
    private readonly listsPrefix = this.getBaseURL.concat('lists/')
    private readonly integrationsPrefix = this.getBaseURL.concat('integrations/')

    /**
     * Use to get industries list
     */
    public industries(options?: AxiosOptionsAsParam) {
        const url = this.commonPrefix.concat('industries')

        return this.get<IndustriesAPIResponse>(url, options)
    }

    /**
     * Use to get countries list
     */
    public countries(options?: AxiosOptionsAsParam) {
        const url = this.commonPrefix.concat('countries')

        return this.get<CountriesAPIResponse>(url, options)
    }

    /**
     * Use to get countries list (autocomplete)
     */
    public locations(locationName: string) {
        const url = this.commonPrefixV2.concat('locations')
        const queries = new URLSearchParams({ name: locationName })

        return this.get<LocationsApiResponse>(url, { params: queries })
    }

    /**
     * Use to get job title roles list
     */
    public jobTitleRoles(options?: AxiosOptionsAsParam) {
        const url = this.commonPrefix.concat('job_roles')

        return this.get<JobTitleRolesAPIResponse>(url, options)
    }

    /**
     * Use to get job title levels list
     */
    public jobTitleLevels(options?: AxiosOptionsAsParam) {
        const url = this.commonPrefix.concat('job_levels')

        return this.get<JobTitleLevelsAPIResponse>(url, options)
    }

    /**
     * Use to get autocomplete companies list
     * @description companies autocomplete api
     * @param name searched company name
     * @param type type of comapnies datasource:
     * - `linkedin` use it in contact search
     * - `all` use it in company search
     */
    public companies(name: string, type: CompanyAutocompleteType, options?: AxiosOptionsAsParam) {
        const url = this.getBaseURL.concat(`companies/${type}/autocomplete`)
        const queries = new URLSearchParams({ name })

        return this.get<CompaniesAPIResponse>(url, { ...options, params: queries })
    }

    /**
     * Use to get company url
     * @param domain domain's name
     */
    public domains(domain: string, options?: AxiosOptionsAsParam) {
        const url = this.commonPrefix.concat('domains/autocomplete')
        const queries = new URLSearchParams({ name: domain })

        return this.get<DomainsAPIResponse>(url, { params: queries, ...options })
    }

    /**
     * Use to get list of lists
     */
    public lists(params?: ListOfListsApiParams, options?: AxiosOptionsAsParam) {
        const url = this.listsPrefix.slice(0, -1)
        const queries = new URLSearchParams({
            start: params?.start.toString() || '0',
            limit: params?.limit.toString() || '10'
        })

        return this.get<ListsAPIResponse>(url, { params: queries, ...options })
    }

    /**
     * Use to add an item to a list collection
     * @param itemId the item's (that want to be added to a collection) ID
     * @param listId the list ID
     */
    public addToList(params: AddItemToListApiParams, options?: AxiosOptionsAsParam) {
        const url = this.listsPrefix.concat(params.listId.toString()).concat('/items')

        const headers = new AxiosHeaders()
        headers.set('content-type', 'multipart/form-data')

        const requestBody = new FormData()
        requestBody.append('items', JSON.stringify(params.itemsIds))
        requestBody.append('type', params.type)

        return this.post(url, requestBody, { headers, ...options })
    }

    /**
     * Use to add new list to lists
     */
    public addList(params: NewListParams, options?: AxiosOptionsAsParam) {
        const url = this.listsPrefix.slice(0, -1)

        const headers = new AxiosHeaders()
        headers.set('content-type', 'multipart/form-data')

        const requestBody = new FormData()
        requestBody.append('name', params.name)
        requestBody.append('type', params.type)

        return this.post<ListsAPIResponse>(url, requestBody, { headers, ...options })
    }

    /**
     * Use to get all of an item's lists
     * @param itemID item list
     */
    public getItemLists(itemID: string, options?: AxiosOptionsAsParam) {
        const url = this.listsPrefix.concat('items/').concat(itemID).concat('/lists')

        return this.get<ItemListsAPIResponse>(url, options)
    }

    /**
     * Use to delete an item from a list
     * @param itemID
     * @param listID
     */
    public deleteItemFromList(params: DeleteListItemApiParams, options?: AxiosOptionsAsParam) {
        const url = this.listsPrefix.concat(params.listID.toString()).concat('/items/').concat(params.itemID)

        return this.delete(url, options)
    }

    /**
     * Use to get geo location by place name
     * @param token mapbox token
     * @param locationName the location you wanna get the coords
     */
    public async locationCoordinates(params: LocationCoordinatesApiParams, options?: AxiosOptionsAsParam) {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${params.locationName}.json?access_token=${params.token}`
        const response = await axios.get<MapboxGEOCodeModel>(url, options)

        return response.data
    }

    /**
     * Use to get user's credit
     */
    public getCredit(options?: AxiosOptionsAsParam) {
        const url = this.commonPrefix.concat('get_credit')

        return this.get<CreditAPIResponseModel>(url, options)
    }

    /**
     * Use to save new event log
     */
    public newEvent(event: string, params?: NewEventParams, options?: AxiosOptionsAsParam) {
        const url = this.getBaseURL.concat('logs')

        const headers = new AxiosHeaders()
        headers.set('content-type', 'multipart/form-data')

        const requestBody = new FormData()
        requestBody.append('event', event)

        if (params?.type) requestBody.append('type', params.type)

        if (params?.metaData && Object.keys(params.metaData).length > 0)
            requestBody.append('meta_data', JSON.stringify(params.metaData))
        if (params?.pageUrl) requestBody.append('url', params.pageUrl)

        return this.post(url, requestBody, { headers, ...options })
    }

    /**
     * Use to sign the user in hubspot
     */
    public hubspotAuth(authCode: string, options?: AxiosOptionsAsParam) {
        const url = this.integrationsPrefix.concat('hubspot/auth')

        const headers = new AxiosHeaders()
        headers.set('content-type', 'multipart/form-data')

        const requestBody = new FormData()
        requestBody.append('code', authCode)

        return this.post(url, requestBody, { headers, ...options })
    }

    /**
     * Use to add a list of contacts to user's hubspot crm
     */
    public addContactsToHubspot(contacts: string[], options?: AxiosOptionsAsParam) {
        const url = this.integrationsPrefix.concat('hubspot/contacts')

        const headers = new AxiosHeaders()
        headers.set('content-type', 'multipart/form-data')

        const requestBody = new FormData()
        requestBody.append('contacts', JSON.stringify(contacts))

        return this.post(url, requestBody, { headers, ...options })
    }

    /**
     * Use to add a list of companies to user's hubspot crm
     */
    public addCompaniesToHubspot(companies: string[], options?: AxiosOptionsAsParam) {
        const url = this.integrationsPrefix.concat('hubspot/companies')

        const headers = new AxiosHeaders()
        headers.set('content-type', 'multipart/form-data')

        const requestBody = new FormData()
        requestBody.append('companies', JSON.stringify(companies))

        return this.post(url, requestBody, { headers, ...options })
    }

    /**
     * Use to check user's hubspot connection status
     */
    public checkHubspotConnection(options?: AxiosOptionsAsParam) {
        const url = this.integrationsPrefix.concat('hubspot/check')

        return this.get(url, options)
    }

    /**
     * Use to sign the user in zoho crm
     */
    public zohoAuth(authCode: string, options?: AxiosOptionsAsParam) {
        const url = this.integrationsPrefix.concat('zoho/auth')

        const headers = new AxiosHeaders()
        headers.set('content-type', 'multipart/form-data')

        const requestBody = new FormData()
        requestBody.append('code', authCode)

        return this.post(url, requestBody, { headers, ...options })
    }

    /**
     * Use to check user's zoho connection status
     */
    public checkZohoConnection(options?: AxiosOptionsAsParam) {
        const url = this.integrationsPrefix.concat('zoho/check')

        return this.get(url, options)
    }

    /**
     * Use to add a list of contacts to user's zoho crm
     */
    public addContactsToZoho(contacts: string[], options?: AxiosOptionsAsParam) {
        const url = this.integrationsPrefix.concat('zoho/contacts')

        const headers = new AxiosHeaders()
        headers.set('content-type', 'multipart/form-data')

        const requestBody = new FormData()
        requestBody.append('contacts', JSON.stringify(contacts))

        return this.post(url, requestBody, { headers, ...options })
    }

    /**
     * Use to add a list of contacts to user's zoho crm
     */
    public addContactsToSalesforce(contacts: string[], options?: AxiosOptionsAsParam) {
        const url = this.integrationsPrefix.concat('salesforce/contacts')

        const headers = new AxiosHeaders()
        headers.set('content-type', 'multipart/form-data')

        const requestBody = new FormData()
        requestBody.append('contacts', JSON.stringify(contacts))

        return this.post(url, requestBody, { headers, ...options })
    }

    /**
     * Check if email is company email or not
     * @param email is user's entered email
     */
    public verifyEmail({ email, type }: CompanyEmailParams, options?: AxiosOptionsAsParam) {
        const url = this.commonPrefix.concat('verify_email')

        const headers = new AxiosHeaders()
        headers.set('email', email)

        if (type == 'public') headers.set('type', '1')

        if (type == 'private') headers.set('type', '2')

        return this.get(url, { headers, ...options })
    }

    /**
     * get company logo
     * @param domain is company domain
     */
    public getCompanyLogo({ domain }: CompanyLogoParams, options?: AxiosOptionsAsParam) {
        const url = 'https://api.cufinder.io/logo/'.concat(domain)

        return this.get(url, options)
    }
}
