export type FAQ = {
    id: number
    question: string
    answer: string
}

export type PlanType = 'monthly' | 'yearly'
export type PlanEngine = 'lead' | 'enrichment'
export enum PlanPriority {
    FREE = 0,
    GROWTH,
    PREMIUM,
    UNLIMITED
}
