// ** react imports
import { useEffect, Fragment } from 'react'

// ** react router imports
import { useLocation } from 'react-router-dom'

// ** mui imports
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import { styled, useTheme } from '@mui/material/styles'
import ListItemButton from '@mui/material/ListItemButton'

// ** third party imports
import clsx from 'clsx'

// ** configs import
import themeConfig from 'src/configs/themeConfig'

// ** utils imports
import { hasActiveChild, removeChildren } from 'src/@core/layouts/utils'

// ** types imports
import { NavGroup } from 'src/@core/layouts/types'
import { Settings } from 'src/@core/context/settingsContext'

// ** custom components imports
import VerticalNavItems from './VerticalNavItems'
import UserIcon from 'src/layouts/components/UserIcon'
import Translations from 'src/layouts/components/Translations'
import CanViewNavGroup from 'src/layouts/components/acl/CanViewNavGroup'
import Icon from 'src/@core/components/icon'

interface Props {
    item: NavGroup
    navHover: boolean
    parent?: NavGroup
    settings: Settings
    navVisible?: boolean
    groupActive: string[]
    collapsedNavWidth: number
    currentActiveGroup: string[]
    navigationBorderWidth: number
    isSubToSub?: NavGroup | undefined
    saveSettings: (values: Partial<Settings>) => void
    setGroupActive: (values: string[]) => void
    setCurrentActiveGroup: (items: string[]) => void
}

const MenuItemTextWrapper = styled(Box)<BoxProps>(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    transition: 'opacity .25s ease-in-out',
    ...(themeConfig.menuTextTruncate && { overflow: 'hidden' })
}))

const VerticalNavGroup = (props: Props) => {
    // ** Props
    const {
        item,
        parent,
        settings,
        navHover,
        navVisible,
        isSubToSub,
        groupActive,
        setGroupActive,
        collapsedNavWidth,
        currentActiveGroup,
        setCurrentActiveGroup,
        navigationBorderWidth
    } = props

    // ** Hooks & Vars
    const theme = useTheme()
    const location = useLocation()
    const currentURL = location.pathname
    const { skin, direction, navCollapsed, verticalNavToggleType } = settings

    // ** Accordion menu group open toggle
    const toggleActiveGroup = (item: NavGroup, parent: NavGroup | undefined) => {
        let openGroup = groupActive

        // ** If Group is already open and clicked, close the group
        if (openGroup.includes(item.title)) {
            openGroup.splice(openGroup.indexOf(item.title), 1)

            // If clicked Group has open group children, Also remove those children to close those groups
            if (item.children) {
                removeChildren(item.children, openGroup, currentActiveGroup)
            }
        } else if (parent) {
            // ** If Group clicked is the child of an open group, first remove all the open groups under that parent
            if (parent.children) {
                removeChildren(parent.children, openGroup, currentActiveGroup)
            }

            // ** After removing all the open groups under that parent, add the clicked group to open group array
            if (!openGroup.includes(item.title)) {
                openGroup.push(item.title)
            }
        } else {
            // ** If clicked on another group that is not active or open, create openGroup array from scratch

            // ** Empty Open Group array
            openGroup = []

            // ** push Current Active Group To Open Group array
            if (currentActiveGroup.every((elem) => groupActive.includes(elem))) {
                openGroup.push(...currentActiveGroup)
            }

            // ** Push current clicked group item to Open Group array
            if (!openGroup.includes(item.title)) {
                openGroup.push(item.title)
            }
        }
        setGroupActive([...openGroup])
    }

    // ** Menu Group Click
    const handleGroupClick = () => {
        const openGroup = groupActive
        if (verticalNavToggleType === 'collapse') {
            if (openGroup.includes(item.title)) {
                openGroup.splice(openGroup.indexOf(item.title), 1)
            } else {
                openGroup.push(item.title)
            }
            setGroupActive([...openGroup])
        } else {
            toggleActiveGroup(item, parent)
        }
    }

    useEffect(() => {
        if (hasActiveChild(item, currentURL)) {
            if (!groupActive.includes(item.title)) groupActive.push(item.title)
        } else {
            const index = groupActive.indexOf(item.title)
            if (index > -1) groupActive.splice(index, 1)
        }
        setGroupActive([...groupActive])
        setCurrentActiveGroup([...groupActive])

        // Empty Active Group When Menu is collapsed and not hovered, to fix issue route change
        if (navCollapsed && !navHover) {
            setGroupActive([])
        }
    }, [location.pathname])

    useEffect(() => {
        if (navCollapsed && !navHover) {
            setGroupActive([])
        }

        if ((navCollapsed && navHover) || (groupActive.length === 0 && !navCollapsed)) {
            setGroupActive([...currentActiveGroup])
        }
    }, [navCollapsed, navHover])

    useEffect(() => {
        if (groupActive.length === 0 && !navCollapsed) {
            setGroupActive([])
        }
    }, [navHover])

    const IconTag = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon

    const menuGroupCollapsedStyles = navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 }

    const conditionalIconColor = () => {
        if (skin === 'semi-dark' && theme.palette.mode === 'light') {
            return {
                color: `rgba(${theme.palette.customColors.dark}, ${parent && item.children ? 0.68 : 0.87})`
            }
        } else if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
            return {
                color: `rgba(${theme.palette.customColors.light}, ${parent && item.children ? 0.68 : 0.87})`
            }
        } else
            return {
                color: parent && item.children ? theme.palette.text.secondary : theme.palette.text.primary
            }
    }

    const conditionalArrowIconColor = () => {
        if (skin === 'semi-dark' && theme.palette.mode === 'light') {
            return {
                color: `rgba(${theme.palette.customColors.dark}, 0.68)`
            }
        } else if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
            return {
                color: `rgba(${theme.palette.customColors.light}, 0.68)`
            }
        } else return {}
    }

    const conditionalBgColor = () => {
        if (skin === 'semi-dark' && theme.palette.mode === 'light') {
            return {
                '&:hover': {
                    backgroundColor: `rgba(${theme.palette.customColors.dark}, 0.05)`
                },
                '&.Mui-selected': {
                    backgroundColor: `rgba(${theme.palette.customColors.dark}, 0.08)`,
                    '&:hover': {
                        backgroundColor: `rgba(${theme.palette.customColors.dark}, 0.08)`
                    }
                }
            }
        } else if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
            return {
                '&:hover': {
                    backgroundColor: `rgba(${theme.palette.customColors.light}, 0.05)`
                },
                '&.Mui-selected': {
                    backgroundColor: `rgba(${theme.palette.customColors.light}, 0.08)`,
                    '&:hover': {
                        backgroundColor: `rgba(${theme.palette.customColors.light}, 0.08)`
                    }
                }
            }
        } else {
            return {
                '&.Mui-selected': {
                    backgroundColor: theme.palette.action.selected,
                    '&:hover': {
                        backgroundColor: theme.palette.action.selected
                    }
                }
            }
        }
    }

    return (
        <CanViewNavGroup navGroup={item}>
            <Fragment>
                <ListItem
                    disablePadding
                    className='nav-group'
                    onClick={handleGroupClick}
                    sx={{
                        mt: 1.5,
                        flexDirection: 'column',
                        transition: 'padding .25s ease-in-out',
                        px:
                            parent && item.children
                                ? '0 !important'
                                : `${theme.spacing(navCollapsed && !navHover ? 2 : 3)} !important`
                    }}
                >
                    <ListItemButton
                        className={clsx({
                            'Mui-selected': groupActive.includes(item.title) || currentActiveGroup.includes(item.title)
                        })}
                        sx={{
                            py: 2.25,
                            width: '100%',
                            borderRadius: '8px',
                            ...conditionalBgColor(),
                            transition: 'padding-left .25s ease-in-out',
                            pr:
                                navCollapsed && !navHover
                                    ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8
                                    : 3,
                            pl:
                                navCollapsed && !navHover
                                    ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8
                                    : 4
                        }}
                    >
                        {isSubToSub ? null : (
                            <ListItemIcon
                                sx={{
                                    ...conditionalIconColor(),
                                    transition: 'margin .25s ease-in-out',
                                    ...(parent && navCollapsed && !navHover ? {} : { mr: 2 }),
                                    ...(navCollapsed && !navHover ? { mr: 0 } : {}), // this condition should come after (parent && navCollapsed && !navHover) condition for proper styling
                                    ...(parent && item.children ? { ml: 2, mr: 4 } : {})
                                }}
                            >
                                <UserIcon
                                    icon={IconTag}
                                    componentType='vertical-menu'
                                    {...(parent ? { fontSize: '0.5rem' } : { fontSize: '1.5rem' })}
                                />
                            </ListItemIcon>
                        )}
                        <MenuItemTextWrapper sx={{ ...menuGroupCollapsedStyles, ...(isSubToSub ? { ml: 8 } : {}) }}>
                            <Typography
                                {...((themeConfig.menuTextTruncate ||
                                    (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
                                    noWrap: true
                                })}
                            >
                                <Translations text={item.title} />
                            </Typography>
                            <Box className='menu-item-meta' sx={{ ml: 1.5, display: 'flex', alignItems: 'center' }}>
                                {item.badgeContent ? (
                                    <Chip
                                        size='small'
                                        label={item.badgeContent}
                                        color={item.badgeColor || 'primary'}
                                        sx={{
                                            mr: 0.75,
                                            '& .MuiChip-label': {
                                                px: 2.5,
                                                lineHeight: 1.385,
                                                textTransform: 'capitalize'
                                            }
                                        }}
                                    />
                                ) : null}
                                {direction === 'ltr' ? (
                                    <Icon
                                        icon='mdi:chevron-right'
                                        style={{
                                            transition: 'transform .25s ease-in-out',
                                            ...conditionalArrowIconColor(),
                                            ...(groupActive.includes(item.title) ? { transform: 'rotate(90deg)' } : {})
                                        }}
                                    />
                                ) : (
                                    <Icon
                                        icon='mdi:chevron-left'
                                        style={{
                                            transition: 'transform .25s ease-in-out',
                                            ...conditionalArrowIconColor(),
                                            ...(groupActive.includes(item.title) ? { transform: 'rotate(-90deg)' } : {})
                                        }}
                                    />
                                )}
                            </Box>
                        </MenuItemTextWrapper>
                    </ListItemButton>
                    <Collapse
                        component='ul'
                        onClick={(e) => e.stopPropagation()}
                        in={groupActive.includes(item.title)}
                        sx={{
                            pl: 0,
                            width: '100%',
                            ...menuGroupCollapsedStyles,
                            transition: 'all .25s ease-in-out'
                        }}
                    >
                        <VerticalNavItems
                            {...props}
                            parent={item}
                            navVisible={navVisible}
                            verticalNavItems={item.children}
                            isSubToSub={parent && item.children ? item : undefined}
                        />
                    </Collapse>
                </ListItem>
            </Fragment>
        </CanViewNavGroup>
    )
}

export default VerticalNavGroup
