// ** react imports
import { Fragment, SyntheticEvent, useState } from 'react'

// ** react router imports
import { Link, useNavigate } from 'react-router-dom'

// ** redux imports
import { useSelector } from 'react-redux'

// ** store imports
import { RootState } from 'src/store'

// ** mui imports
import Stack from '@mui/material/Stack'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

// ** custom components imports
import Button from 'src/components/Button'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import NotificationsDropdown from 'src/@core/layouts/components/shared-components/NotificationsDropdown'
import Icon from 'src/@core/components/icon'

// ** type import
import { Settings } from 'src/@core/context/settingsContext'
import { UserRoleType } from 'src/models'

interface Props {
    hidden: boolean
    settings: Settings
    saveSettings: (values: Partial<Settings>) => void
}

const styles = {
    py: 2,
    px: 4,
    width: '100%',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
        fontSize: '1.375rem'
    }
}

const AppBarContent = (props: Props) => {
    // ** vars
    const { settings } = props

    // ** states
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)

    // ** hooks
    const { user } = useSelector((state: RootState) => state.auth.auth)
    const navigate = useNavigate()

    // ** methods
    const handleDropdownOpen = (event: SyntheticEvent) => {
        setAnchorEl(event.currentTarget)
    }

    const handleDropdownClose = (url?: string) => {
        if (url) navigate(url)

        setAnchorEl(null)
    }

    return (
        <Stack direction='row' alignItems='center' columnGap={2}>
            {user?.role != UserRoleType.B2B ? (
                <Fragment>
                    <Button
                        color='secondary'
                        href='/lists'
                        startIcon={<Icon icon='mdi:clipboard-list-outline' />}
                        size='small'
                        sx={{ minWidth: '0!important', width: '6rem' }}
                    >
                        lists
                    </Button>
                    <Button
                        id='appbar-upgrade-btn'
                        color='success'
                        startIcon={<Icon icon='mdi:format-list-bulleted' />}
                        onClick={handleDropdownOpen}
                        size='small'
                        sx={{ minWidth: '0!important', width: '6rem' }}
                    >
                        upgrade
                    </Button>
                </Fragment>
            ) : null}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleDropdownClose()}
                sx={{ '& .MuiMenu-paper': { minWidth: 230, mt: 2 } }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem sx={{ p: 0 }} onClick={() => setAnchorEl(null)}>
                    <Link to='/plans/enrichment-engine'>
                        <Stack direction='row' sx={styles}>
                            <Icon icon='mdi:account-cog-outline' style={{ marginRight: '0.5rem' }} />
                            Enrichment Engine Plans
                        </Stack>
                    </Link>
                </MenuItem>
                <MenuItem sx={{ p: 0 }} onClick={() => setAnchorEl(null)}>
                    <Link to='/plans/lead-generation'>
                        <Stack direction='row' sx={styles}>
                            <Icon icon='mdi:target-account' style={{ marginRight: '0.5rem' }} />
                            Lead Generation Plans
                        </Stack>
                    </Link>
                </MenuItem>
            </Menu>
            <NotificationsDropdown settings={settings} />
            <UserDropdown settings={settings} />
        </Stack>
    )
}

export default AppBarContent
