// ** redux imports
import { createSlice } from '@reduxjs/toolkit'

// ** user actions
import { getLogoutUser, getChangeForgetPasswordStep, setUserInStore, updateUserInStore } from './actions'

// ** types
import { AuthStateModel } from './types'

// ** initial state
const initialState: AuthStateModel = {
    loading: false,
    auth: {
        forgetPasswordStep: 1,
        user: null
    },
    error: ''
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setForgetPasswordStep: getChangeForgetPasswordStep,
        setUser: setUserInStore,
        updateUser: updateUserInStore,
        logout: getLogoutUser
    }
})

export const authReducer = authSlice.reducer
export const { setForgetPasswordStep, setUser, updateUser, logout } = authSlice.actions
