// ** react imports
import { FC } from 'react'

// ** react router imports
import { RouterProvider } from 'react-router-dom'

// ** routes imports
import mainRoutes from './routes/main.routes'

// ** store imports
import { store } from 'src/store'
import { Provider } from 'react-redux'

// ** third party imports
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        },
        mutations: {
            retry: false
        }
    }
})

const App: FC = () => {
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <HelmetProvider>
                    <RouterProvider router={mainRoutes} future={{ v7_startTransition: true }} />
                </HelmetProvider>
            </QueryClientProvider>
        </Provider>
    )
}

export default App
