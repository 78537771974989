// ** third party imports
import { jsonMember, jsonObject } from 'typedjson'

export enum TeamStatus {
    OWNER = -1,
    PENDING = 0,
    ACCEPTED,
    REJECTED
}

@jsonObject
export class TeamModel {
    @jsonMember(Number)
    id: number

    @jsonMember(String)
    team_id: string

    @jsonMember(Number)
    user_id: number

    @jsonMember(Number)
    team_head_id: number

    @jsonMember(Number)
    status: TeamStatus

    @jsonMember(Date)
    created_at: Date
}
