// ** typedjson imports
import { jsonObject, jsonMember } from 'typedjson'

// ** types imports
import type { ListType } from 'src/types'

@jsonObject
export class ListModel {
    @jsonMember(Number)
    id: number

    @jsonMember(String)
    name: string

    @jsonMember(String)
    type: ListType

    @jsonMember(Boolean)
    is_default: boolean
}
