// ** third party imports
import { Icon, IconProps } from '@iconify/react'

interface UserIconProps extends IconProps {
    icon: string
    componentType: 'search' | 'vertical-menu' | 'horizontal-menu'
}

const UserIcon = (props: UserIconProps) => {
    // ** Props
    const { icon, componentType, ...iconProps } = props

    let styles

    if (componentType === 'search') {
        // Conditional Props based on component type, like have different font size or icon color
        /* styles = {
      color: 'blue',
      fontSize: '2rem'
    } */
    } else if (componentType === 'vertical-menu') {
        // Conditional Props based on component type, like have different font size or icon color
        /* styles = {
      color: 'red',
      fontSize: '1.5rem'
    } */
    } else if (componentType === 'horizontal-menu') {
        // Conditional Props based on component type, like have different font size or icon color
        /* styles = {
      color: 'green',
      fontSize: '1rem'
    } */
    } else {
        return null
    }

    return <Icon icon={icon} {...iconProps} />
}

export default UserIcon
