// ** types import
import type { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {
    return [
        {
            icon: 'mdi:target-account',
            title: 'Prospect Engine',
            path: '/prospect/contact',
            action: 'menu',
            subject: 'prospect-engine'
        },
        {
            icon: 'mdi:account-cog-outline',
            title: 'Enrichment Engine',
            path: '/enrichment/services',
            action: 'menu',
            subject: 'enrichment-engine'
        },
        {
            icon: 'fluent:task-list-square-person-20-regular',
            title: 'Create B2B List',
            path: '/business-directory/search',
            action: 'menu',
            subject: 'industrial-business-directory'
        },
        {
            icon: 'gala:search',
            title: 'Search B2B Data',
            path: '/business-directory/search',
            action: 'menu',
            subject: 'business-directory'
        },
        {
            icon: 'icon-park-outline:transaction-order',
            title: 'Orders List',
            path: '/business-directory/orders',
            action: 'menu',
            subject: 'business-directory'
        }
        // {
        //     icon: 'mdi:account-search',
        //     title: 'HR Engine',
        //     path: '/hr',
        //     action: 'menu',
        //     subject: 'hr-engine'
        // },
    ]
}

export default navigation
