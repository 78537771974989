// ** typedjson imports
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class CountryModel {
    @jsonMember(String)
    code: string

    @jsonMember(String)
    name: string
}
