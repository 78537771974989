// ** react imports
import { PropsWithChildren, ReactNode } from 'react'

// ** react router imports
import { Link } from 'react-router-dom'

// ** mui imports
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

// ** custom components imports
import Image from 'src/components/Image'

// ** types imports
import { Settings } from 'src/@core/context/settingsContext'

interface Props {
    hidden: boolean
    settings: Settings
    saveSettings: (values: Settings) => void
    horizontalAppBarContent?: (props?: any) => ReactNode
    horizontalAppBarBranding?: (props?: any) => ReactNode
}

const StyledLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    marginRight: theme.spacing(8)
}))

const AppBarContent = (props: PropsWithChildren<Props>) => {
    // ** Props
    const {
        horizontalAppBarContent: userHorizontalAppBarContent,
        horizontalAppBarBranding: userHorizontalAppBarBranding
    } = props

    return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
            {userHorizontalAppBarBranding ? (
                userHorizontalAppBarBranding(props)
            ) : (
                <StyledLink to='/'>
                    <Image src='/images/logo.svg' alt='CUF' sx={{ width: '120px', height: '30px' }} />
                </StyledLink>
            )}
            {props.children}
            {userHorizontalAppBarContent ? userHorizontalAppBarContent(props) : null}
        </Stack>
    )
}

export default AppBarContent
