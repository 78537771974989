// ** third party imports
import { jsonMember, jsonObject } from 'typedjson'

export enum TicketStatus {
    USER_CREATION = 0,
    USER_ANSWER,
    ADMIN_ANSWER,
    ADMIN_CREATION
}

@jsonObject
export class TicketModel {
    @jsonMember(Number)
    id: number

    @jsonMember(Number)
    user_id: number

    @jsonMember(Date)
    creation_time: Date

    @jsonMember(Number)
    action: TicketStatus

    @jsonMember(String)
    title: string

    @jsonMember(String)
    text: string

    @jsonMember(Date)
    created_at: Date

    @jsonMember(Date)
    updated_at: Date
}
