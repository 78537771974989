// ** typedjson imports
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class ColleagueModel {
    @jsonMember(String)
    _id: string

    @jsonMember(String)
    full_name: string | null

    @jsonMember(String)
    job_title: string | null

    @jsonMember(String)
    job_title_role: string | null

    @jsonMember(String)
    linkedin_username: string | null
}
