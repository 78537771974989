// ** typedjson imports
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'

// ** models imports
import { PlanItemModel } from './plan_item.model'

// ** types imports
import type { PlanEngine, PlanPriority, PlanType } from 'src/types'

@jsonObject
export class PlanModel {
    @jsonMember(Number)
    id: number

    @jsonMember(String)
    name: string

    @jsonMember(Number)
    price: number | number[]

    @jsonMember(Number)
    credits: number | number[]

    @jsonMember(Number)
    fair_credits: number | null

    @jsonMember(Number)
    mobile_credits: number | null

    @jsonMember(String)
    type: PlanType

    @jsonMember(String)
    engine: PlanEngine

    @jsonArrayMember(PlanItemModel)
    items: PlanItemModel[]

    @jsonMember(String)
    price_id: string | string[]

    @jsonMember(Number)
    priority: PlanPriority

    @jsonMember(String)
    enabled: boolean | string

    @jsonMember(String)
    slogan: string

    @jsonMember(Date)
    created_at: Date

    @jsonMember(Date)
    updated_at: Date
}
