// ** third party imports
import { EditorState } from 'react-draft-wysiwyg'

export enum CampaignStatus {
    PAUSED = 0,
    ACTIVE
}

export enum LeadAcitivityStatus {
    SENT = 2,
    OPENED,
    REPLIED
}

type CampaignSenderType = {
    label: string
    isActive: boolean
    value: string
}

type CampaignScheduleModel = {
    order: number
    name: string
    start_time: string
    end_time: string
    timezone: string
    days: Array<string>
}

type CampaignStepModel = {
    order: number
    subject: string
    content: EditorState | null
    offset: number
}

type CampaignOptionsModel = {
    senders: Array<CampaignSenderType>
    stopOnReply: boolean
    stopOnAutoreply: boolean
    openTracking: boolean
    linkTracking: boolean
    textOnly: boolean
    dailyLimit: number
    timeGapMin?: number
    timeGapMax?: number
    maxNewLeads?: number
    cc: Array<string>
    bcc: Array<string>
}

type NewCampaignModel = CampaignOptionsModel & {
    name: string
    status: CampaignStatus
    start_date: Date | null
    end_date: Date | null
    schedules: Array<CampaignScheduleModel>
    steps: Array<CampaignStepModel>
}

type CampaignModel = Omit<NewCampaignModel, 'steps'> & {
    steps: Array<Omit<CampaignStepModel, 'content'> & { content: string }>
}

export type { CampaignModel, CampaignScheduleModel, CampaignStepModel, CampaignOptionsModel, NewCampaignModel }
