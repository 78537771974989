// ** Type Imports
import { PaletteMode } from '@mui/material'
import { Skin } from 'src/@core/layouts/types'

const DefaultPalette = (mode: PaletteMode, skin: Skin) => {
    // ** Vars
    const lightColor = '26, 26, 26' // '76, 78, 100';
    const darkColor = '234, 234, 255'
    const mainColor = mode === 'light' ? lightColor : darkColor

    const defaultBgColor = () => {
        if (skin === 'bordered' && mode === 'light') {
            return '#FFF'
        } else if (skin === 'bordered' && mode === 'dark') {
            return '#30334E'
        } else if (mode === 'light') {
            return '#F7F7F9'
        } else return '#282A42'
    }

    return {
        customColors: {
            dark: darkColor,
            main: mainColor,
            light: lightColor,
            darkBg: '#282A42',
            lightBg: '#F7F7F9',
            bodyBg: mode === 'light' ? '#F7F7F9' : '#282A42', // Same as palette.background.default but doesn't consider bordered skin
            tooltipBg: mode === 'light' ? '#262732' : '#464A65',
            tableHeaderBg: mode === 'light' ? '#F5F5F7' : '#3A3E5B'
        },
        common: {
            black: '#000',
            white: '#FFF'
        },
        mode: mode,
        primary: {
            light: '#787EFF',
            main: '#2667FF', // '#666CFF',
            dark: '#2667FF', // '#5A5FE0',
            contrastText: '#FFF'
        },
        secondary: {
            light: '#7F889B',
            main: '#6D788D',
            dark: '#606A7C',
            contrastText: '#FFF'
        },
        success: {
            light: '#83E542',
            main: '#22C55E',
            dark: '#22C55E',
            contrastText: '#FFF'
        },
        error: {
            light: '#FF625F',
            main: '#F43F5E',
            dark: '#E04440',
            contrastText: '#FFF'
        },
        warning: {
            light: '#FDBE42',
            main: '#EAB308',
            dark: '#DF9F23',
            contrastText: '#FFF'
        },
        info: {
            light: '#40CDFA',
            main: '#3B82F6',
            dark: '#21AEDB',
            contrastText: '#FFF'
        },
        prospect_engine: {
            main: '#ff554d'
        },
        enrichment_engine: {
            main: '#01077a'
        },
        business_directory_engine: {
            main: '#D95D39'
        },
        hr_engine: {
            main: '#f1b419'
        },
        email_finder_engine: {
            main: '#5e1779'
        },
        phone_finder_engine: {
            main: '#A6261D'
        },
        grey: {
            50: '#FAFAFA',
            100: '#F5F5F5',
            200: '#EEEEEE',
            300: '#E0E0E0',
            400: '#BDBDBD',
            500: '#9E9E9E',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
            A100: '#D5D5D5',
            A200: '#1A1A1A',
            A400: '#616161',
            A700: '#303030'
        },
        text: {
            primary: `rgba(${mainColor}, 1)`,
            secondary: `rgba(${mainColor}, 0.68)`,
            disabled: `rgba(${mainColor}, 0.38)`
        },
        divider: `rgba(${mainColor}, 0.12)`,
        background: {
            paper: mode === 'light' ? '#FFF' : '#30334E',
            default: defaultBgColor()
        },
        action: {
            active: `rgba(${mainColor}, 0.54)`,
            hover: `rgba(${mainColor}, 0.05)`,
            hoverOpacity: 0.05,
            selected: `rgba(${mainColor}, 0.08)`,
            disabled: `rgba(${mainColor}, 0.26)`,
            disabledBackground: `rgba(${mainColor}, 0.12)`,
            focus: `rgba(${mainColor}, 0.12)`
        }
    }
}

export default DefaultPalette
