// ** redux imports
import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

// ** reducers
import { authReducer } from './features/auth'

export const store = configureStore({
    reducer: {
        auth: authReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch: () => AppDispatch = useDispatch
