// ** react imports
import { FC } from 'react'

// ** mui imports
import MuiImage, { AvatarProps as MuiImageProps } from '@mui/material/Avatar'

export interface ImageProps extends MuiImageProps {}

const Image: FC<ImageProps> = ({ ...props }) => {
    return (
        <MuiImage
            variant='rounded'
            {...props}
            imgProps={{ ...props.imgProps, sx: { objectFit: 'contain', ...props.imgProps?.sx } }}
        />
    )
}

export default Image
