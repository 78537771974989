const breakpoints = () => ({
    values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1520 // 1536
    }
})

export default breakpoints
