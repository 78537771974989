// ** typedjson imports
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class CompanyModel {
    @jsonMember(String)
    _id: string

    @jsonMember(Boolean)
    has_email: boolean

    @jsonMember(Boolean)
    has_phone: boolean

    @jsonMember(String)
    job_company_facebook_url: string | null

    @jsonMember(String)
    job_company_industry: string | null

    @jsonMember(String)
    job_company_linkedin_url: string | null

    @jsonMember(String)
    job_company_location_country: string | null

    @jsonMember(String)
    job_company_location_name: string | null

    @jsonMember(String)
    job_company_name: string | null

    @jsonMember(String)
    job_company_size: string | null

    @jsonMember(String)
    job_company_twitter_url: string | null

    @jsonMember(String)
    job_company_website: string | null

    @jsonArrayMember(String)
    products: string[]

    @jsonArrayMember(String)
    technologies: string[]
}

@jsonObject
export class CompanyAutocompleteModel {
    @jsonMember(String)
    company_name: string

    @jsonMember(String)
    company_website: string | null

    @jsonMember(String)
    company_linkedin_url: string | null
}
