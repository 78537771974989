// ** react router imports
import { SetURLSearchParams } from 'react-router-dom'

// ** node imports
import { URLSearchParams } from 'url'

type FormatterParams<T, U> = {
    queryKey: T
    queryValue: Array<U>
    page?: number
    formatter?: (item: U) => any
    splitter?: string
}

export const setQueries = <T extends string, U>(
    [query, setQuery]: [URLSearchParams, SetURLSearchParams],
    params: FormatterParams<T, U>
): void => {
    const pg = !!query.get(params.queryKey) ? (params.page! + 1).toString() : '1'

    if (params.queryValue.length > 0) {
        query.set(
            params.queryKey,
            encodeURIComponent(
                params?.formatter
                    ? params?.queryValue.map(params.formatter).join(params?.splitter)
                    : params.queryValue.join(params?.splitter)
            )
        )
        query.set('page', pg)
        setQuery(query)

        return
    }

    const { page, [params.queryKey]: currentQuery, ...otherQueries } = Object.fromEntries(query.entries())

    if (Object.keys(otherQueries).length == 0) query.delete('page')

    query.delete(params.queryKey)
    setQuery(query)
}
