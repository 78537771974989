// ** typedjson imports
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class CompanyLocationModel {
    @jsonMember(String)
    city: string | null

    @jsonMember(String)
    country: string | null

    @jsonMember(Number)
    latitude: number

    @jsonMember(String)
    line1: string | null

    @jsonMember(String)
    line2: string | null

    @jsonMember(Number)
    longitude: number

    @jsonMember(String)
    postal_code: string | null

    @jsonMember(String)
    state: string | null
}

@jsonObject
export class CompanyInfoModel {
    @jsonMember(Boolean)
    exists: boolean

    @jsonMember(String)
    _id: string

    @jsonMember(String)
    job_company_facebook_url: string | null

    @jsonMember(String)
    job_company_founded: string | null

    @jsonMember(String)
    job_company_id: string | null

    @jsonMember(String)
    job_company_industry: string | null

    @jsonMember(String)
    job_company_linkedin_id: string | null

    @jsonMember(String)
    job_company_linkedin_url: string | null

    @jsonMember(String)
    job_company_location_address_line_2: string | null

    @jsonMember(String)
    job_company_location_continent: string | null

    @jsonMember(String)
    job_company_location_country: string | null

    @jsonMember(String)
    job_company_location_geo: string | null

    @jsonMember(String)
    job_company_location_locality: string | null

    @jsonMember(String)
    job_company_location_metro: string | null

    @jsonMember(String)
    job_company_location_name: string | null

    @jsonMember(String)
    job_company_location_postal_code: string | null

    @jsonMember(String)
    job_company_location_region: string | null

    @jsonMember(String)
    job_company_location_street_address: string | null

    @jsonMember(String)
    job_company_name: string | null

    @jsonMember(String)
    job_company_size: string | null

    @jsonMember(String)
    job_company_twitter_url: string | null

    @jsonMember(String)
    job_company_website: string | null

    @jsonArrayMember(String)
    job_company_specialties: Array<string>

    @jsonMember(String)
    job_company_overview: string | null

    @jsonArrayMember(String)
    job_company_emails: Array<string>

    @jsonArrayMember(String)
    job_company_phones: Array<string>

    @jsonArrayMember(String)
    company_affiliated_pages: Array<string>

    @jsonArrayMember(String)
    company_people_also_viewed: Array<string>

    @jsonArrayMember(CompanyLocationModel)
    company_locations: Array<CompanyLocationModel>

    @jsonMember(String)
    company_funding_last_round_founding_url: string | null

    @jsonMember(String)
    company_funding_last_round_investors_url: string | null

    @jsonMember(String)
    company_funding_last_round_money_raised_amount: string | null

    @jsonMember(String)
    company_funding_last_round_money_raised_amount_currency_code: string | null

    @jsonMember(String)
    company_funding_last_round_type: string | null

    @jsonMember(Number)
    company_funding_number_of_other_investors: number | null

    @jsonMember(Number)
    company_funding_number_of_rounds: number | null

    @jsonMember(String)
    company_funding_organization_url: string | null

    @jsonMember(String)
    company_funding_rounds_url: string | null

    @jsonMember(String)
    company_funding_updated_at: string | null

    @jsonMember(String)
    annual_revenue: string | null

    @jsonMember(Number)
    annual_revenue_count: number | null

    @jsonMember(Number)
    employees_count: number | null

    @jsonMember(Boolean)
    has_email: boolean

    @jsonMember(Boolean)
    has_phone: boolean

    @jsonArrayMember(String)
    products: Array<string>

    @jsonArrayMember(String)
    technologies: Array<string>
}
