// ** react imports
import { FC } from 'react'
import { useSelector } from 'react-redux'

// ** react router imports
import { Navigate } from 'react-router-dom'

// ** redux & store imports
import { RootState } from 'src/store'

const DefaultScreen: FC = () => {
    const { user } = useSelector((state: RootState) => state.auth.auth)

    // ** methods
    const handleUserInfo = () => {
        let redirectURL = null

        switch (user!.role) {
            case 'freelancer':
            case 'manager':
            case 'marketing specialist':
            case 'sales representative':
            case 'software engineer/developer':
                redirectURL = '/prospect/contact'
                break
            case 'b2b':
                redirectURL = '/business-directory/search'
                break
            default:
                redirectURL = '/prospect/contact'
        }

        return redirectURL
    }

    return <Navigate to={handleUserInfo()} replace />
}

export default DefaultScreen
