// ** typedjson imports
import { jsonMember, jsonObject } from 'typedjson'

// ** types imports
import type { SearchType } from 'src/types'

@jsonObject
export class SearchModel {
    @jsonMember(Number)
    id: number

    @jsonMember(String)
    name: string

    @jsonMember(String)
    type: SearchType

    @jsonMember(String)
    search: string

    @jsonMember(String)
    created_at: string
}
