// ** react imports
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

// ** main app file imports
import App from './App'

// ** analytics imports
import reportWebVitals from './reportWebVitals'

// ** styles imports
import './assets/index.scss'

const rootElement = document.getElementById('root') as HTMLElement
const root = createRoot(rootElement)

root.render(
    <StrictMode>
        <App />
    </StrictMode>
)

reportWebVitals()
