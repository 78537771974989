// ** react imports
import { useState } from 'react'

// ** mui imports
import Fab from '@mui/material/Fab'
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'

// ** configs imports
import themeConfig from 'src/configs/themeConfig'

// ** layout imports
import AppBar from './components/vertical/appBar'
import Navigation from './components/vertical/navigation'
import ScrollToTop from 'src/@core/components/scroll-to-top'

// ** custom components import
import Icon from 'src/@core/components/icon'

// ** styled components
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'

// ** types imports
import { LayoutProps } from 'src/@core/layouts/types'

const VerticalLayoutWrapper = styled('div')({
    height: '100%',
    display: 'flex'
})

const MainContentWrapper = styled(Box)<BoxProps>({
    flexGrow: 1,
    minWidth: 0,
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column'
})

const ContentWrapper = styled('main')(({ theme }) => ({
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(6),
    transition: 'padding .25s ease-in-out',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    }
}))

const VerticalLayout = (props: LayoutProps) => {
    // ** Props
    const { hidden, settings, children, scrollToTop } = props

    // ** Vars
    const { skin, navHidden, contentWidth } = settings
    const { navigationSize, collapsedNavigationSize } = themeConfig
    const navWidth = navigationSize
    const navigationBorderWidth = skin === 'bordered' ? 1 : 0
    const collapsedNavWidth = collapsedNavigationSize

    // ** States
    const [navHover, setNavHover] = useState<boolean>(false)
    const [navVisible, setNavVisible] = useState<boolean>(false)

    // ** Toggle Functions
    const toggleNavVisibility = () => setNavVisible(!navVisible)

    return (
        <>
            <VerticalLayoutWrapper className='layout-wrapper'>
                {/* Navigation Menu */}
                {navHidden &&
                themeConfig.layout === 'vertical' &&
                !(navHidden && settings.lastLayout === 'horizontal') ? null : (
                    <Navigation
                        navWidth={navWidth}
                        navHover={navHover}
                        navVisible={navVisible}
                        setNavHover={setNavHover}
                        setNavVisible={setNavVisible}
                        collapsedNavWidth={collapsedNavWidth}
                        toggleNavVisibility={toggleNavVisibility}
                        navigationBorderWidth={navigationBorderWidth}
                        {...props}
                    />
                )}
                <MainContentWrapper className='layout-content-wrapper'>
                    {/* AppBar Component */}
                    <AppBar toggleNavVisibility={toggleNavVisibility} {...props} />

                    {/* Content */}
                    <ContentWrapper
                        className='layout-page-content'
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            ...(contentWidth === 'boxed' && {
                                mx: 'auto',
                                '@media (min-width:1920px)': { maxWidth: 1920 },
                                '@media (min-width:1280px)': { maxWidth: '100%' }
                            })
                        }}
                    >
                        {children}
                    </ContentWrapper>

                    {/* Footer Component */}
                    {/* <Footer {...props} /> */}

                    {/* Portal for React Datepicker */}
                    <DatePickerWrapper sx={{ zIndex: 11 }}>
                        <Box id='react-datepicker-portal'></Box>
                    </DatePickerWrapper>
                </MainContentWrapper>
            </VerticalLayoutWrapper>

            {/* Scroll to top button */}
            {scrollToTop ? (
                scrollToTop(props)
            ) : (
                <ScrollToTop className='mui-fixed'>
                    <Fab color='primary' size='small' aria-label='scroll back to top'>
                        <Icon icon='mdi:arrow-up' />
                    </Fab>
                </ScrollToTop>
            )}
        </>
    )
}

export default VerticalLayout
