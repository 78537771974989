// ** third party imports
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class NotificationModel {
    @jsonMember(Number)
    id: number

    @jsonMember(String)
    title: string

    @jsonMember(String)
    message: string

    @jsonMember(Boolean)
    is_new: boolean

    @jsonMember(Date)
    date: Date
}
