// ** third party imports
import urlNormalizer, { Options as urlNormalizerOptions } from 'normalize-url'
import { parse } from 'tldts'

type NormalizeUrlOptions = urlNormalizerOptions & {
    /**
     * Removes any subdomain from the URL.
     *
     * @default
     *
     * true
     *
     *
     * @example
     *
     * normalizeUrl('http://www.sindresorhus.com');
     * //=> 'http://sindresorhus.com'
     *
     * normalizeUrl('http://subdomain.sindresorhus.com', {stripSubdomain: false});
     * //=> 'http://subdomain.sindresorhus.com'
     */
    stripSubdomain?: boolean

    /**
     * Removes any sub directories from the URL.
     *
     * @default
     *
     * true
     *
     *
     * @example
     *
     * normalizeUrl('http://www.sindresorhus.com/test-subdirectory/test__subdirectory');
     * //=> 'http://sindresorhus.com'
     *
     * normalizeUrl('http://subdomain.sindresorhus.com/test-subdirectory/test__subdirectory', {stripSubdirectories: false});
     * //=> 'http://subdomain.sindresorhus.com/test-subdirectory/test__subdirectory'
     */
    stripSubdirectories?: boolean
}

/**
 * Use to normalize the url
 *
 * @param url the URL
 *
 * @param options helper options
 */
export const normalizeUrl = (url: string, options?: NormalizeUrlOptions) => {
    try {
        if (!url.trim()) return url

        url = url.toLowerCase()

        if (url.trim().includes(' ')) return url

        if (typeof options?.stripSubdirectories == 'undefined' ? true : options.stripSubdirectories) {
            const structuredUrl = new URL(urlNormalizer(url, { defaultProtocol: 'http' }))
            url = url.replace(structuredUrl.pathname.length > 1 ? structuredUrl.pathname : '', '')
        }

        url = urlNormalizer(url.trim(), {
            removeExplicitPort: true,
            stripWWW: true,
            stripProtocol: true,
            stripHash: true,
            removeDirectoryIndex: true,
            ...options
        })

        if (typeof options?.stripSubdomain == 'undefined' ? true : options.stripSubdomain) {
            const sb = parse(url).subdomain
            url = url.replace(sb ? `${sb}.` : '', '')
        }

        return url
    } catch (err) {
        console.error(err)

        return url
    }
}
