// ** react imports
import { FC } from 'react'

// ** react router imports
import { Link, LinkProps } from 'react-router-dom'

// ** mui imports
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography'

export type TypographyProps = ({ to?: undefined | null } & MuiTypographyProps) | (LinkProps & MuiTypographyProps)

const Typography: FC<TypographyProps> = ({ to, children, ...props }) => {
    // ** methods
    const renderNormalTypography = () => <MuiTypography {...props}>{children}</MuiTypography>

    const renderLinkableTypography = () => (
        <MuiTypography component={Link} to={to} {...props} sx={{ textDecoration: 'none', ...props.sx }}>
            {children}
        </MuiTypography>
    )

    return to ? renderLinkableTypography() : renderNormalTypography()
}

export default Typography
