// ** react imports
import { ReactNode, useContext } from 'react'

// ** context imports
import { AbilityContext } from './Can'

// ** types
import { NavGroup, NavLink } from 'src/@core/layouts/types'

interface Props {
    navGroup?: NavGroup
    children: ReactNode
}

const CanViewNavGroup = (props: Props) => {
    // ** props
    const { children, navGroup } = props

    // ** hook
    const ability = useContext(AbilityContext)

    const checkForVisibleChild = (arr: NavLink[] | NavGroup[]): boolean => {
        return arr.some((i: NavGroup) => {
            if (i.children) {
                return checkForVisibleChild(i.children)
            }

            return ability?.can(i.action, i.subject)
        })
    }

    const canViewMenuGroup = (item: NavGroup) => {
        const hasAnyVisibleChild = item.children && checkForVisibleChild(item.children)

        if (!(item.action && item.subject)) {
            return hasAnyVisibleChild
        }

        return ability && ability.can(item.action, item.subject) && hasAnyVisibleChild
    }

    return navGroup && canViewMenuGroup(navGroup) ? <>{children}</> : null
}

export default CanViewNavGroup
