// ** typedjson imports
import { AnyT, jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class ListItemModel {
    @jsonMember(String)
    id: string

    @jsonMember(String)
    data: string

    @jsonMember(String)
    list_id: string

    @jsonMember(String)
    time: string

    @jsonMember(String)
    user_id: string

    @jsonMember(AnyT)
    _id: any
}
