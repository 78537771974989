// ** socket imports
import { Manager } from 'socket.io-client'

const socketSourceUrl = process.env.REACT_APP_SOCKET_URL as string
const socketManager = new Manager(socketSourceUrl, {
    transports: ['websocket'],
    path: '/socket',
    autoConnect: false,
    withCredentials: true,
    reconnectionAttempts: 3,
    reconnectionDelay: 5000
})

export default socketManager
