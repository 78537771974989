// ** typedjson imports
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'

@jsonObject
class MapboxFeaturePropertiesModel {
    @jsonMember(String)
    short_code: string

    @jsonMember(String)
    wikidata: string
}

@jsonObject
class MapboxFeatureGeometryModel {
    @jsonMember(String)
    type: string

    @jsonArrayMember(Number)
    coordinates: [number, number]
}

@jsonObject
export class MapboxFeatureModel {
    @jsonMember(String)
    id: string

    @jsonMember(String)
    type: string

    @jsonArrayMember(String)
    place_type: Array<string>

    @jsonMember(String)
    relevance: number

    @jsonMember(MapboxFeaturePropertiesModel)
    properties: MapboxFeaturePropertiesModel

    @jsonMember(String)
    text: string

    @jsonMember(String)
    place_name: string

    @jsonArrayMember(Number)
    bbox?: [number, number, number, number]

    @jsonArrayMember(Number)
    center: [number, number]

    @jsonMember(MapboxFeatureGeometryModel)
    geometry: MapboxFeatureGeometryModel
}

@jsonObject
export class MapboxGEOCodeModel {
    @jsonMember(String)
    type: string

    @jsonArrayMember(String)
    query: Array<string>

    @jsonArrayMember(MapboxFeatureModel)
    features: Array<MapboxFeatureModel>

    @jsonMember(String)
    attribution: string
}
