// ** React Imports
import { forwardRef } from 'react'

// ** Icon Imports
import MuiAlert, { AlertProps } from '@mui/material/Alert'

export interface SnackProps extends AlertProps {
    message: string

    type?: AlertProps['severity']
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Snack = ({ message, type = 'info', ...props }: SnackProps) => {
    return (
        <Alert elevation={6} variant='filled' severity={type} {...props}>
            {message}
        </Alert>
    )
}

export default Snack
