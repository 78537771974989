// ** custom components imports
import Snack, { SnackProps } from 'src/components/Snack'

// ** third party imports
import hotToast, { ToastOptions } from 'react-hot-toast'

const toast = ({ message, ...snackProps }: SnackProps, toastProps?: ToastOptions) =>
    hotToast(
        () =>
            Snack({
                message,
                ...snackProps
            }),
        {
            duration: 5000,
            style: {
                background: 'transparent',
                boxShadow: 'none',
                padding: 0
            },
            ...toastProps
        }
    )

export { toast, hotToast }
