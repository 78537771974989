// ** mui imports
import MuiChip from '@mui/material/Chip'

// ** hooks imports
import useBgColor, { UseBgColorType } from 'src/@core/hooks/useBgColor'

// ** types
import { CustomChipProps } from './types'

const Chip = (props: CustomChipProps) => {
    // ** props
    const { sx, skin, color } = props

    // ** hook
    const bgColors = useBgColor()

    const colors: UseBgColorType = {
        primary: { ...bgColors.primaryLight },
        secondary: { ...bgColors.secondaryLight },
        success: { ...bgColors.successLight },
        error: { ...bgColors.errorLight },
        warning: { ...bgColors.warningLight },
        info: { ...bgColors.infoLight }
    }

    return (
        <MuiChip
            {...props}
            variant='filled'
            {...(skin === 'light' && { className: 'MuiChip-light' })}
            sx={{
                ...(skin === 'light' &&
                    color && {
                        ...colors[color]
                    }),
                ...sx
            }}
        />
    )
}

export default Chip
