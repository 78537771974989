// ** redux imports
import { store } from 'src/store'
import { logout } from 'src/store/features/auth'

// ** third party imports
import axios, { AxiosError, RawAxiosRequestHeaders, AxiosResponse } from 'axios'

// ** configs imports
import authConfigs from 'src/configs/auth'

// ** types imports
import { ApiRequestOptions, ResponseDetails } from './types'

// ** utils imports
import { toast } from 'src/utils'

// ** error handler imports
import { ResponseCode, getCodeMessage } from './error_handler'

export default abstract class BaseApiService {
    private _baseURL = `${process.env.REACT_APP_BASE_API_URL}api/v1/`
    private _baseURL_V2 = `${process.env.REACT_APP_BASE_API_URL}api/v2/`

    /**
     * Use to get `base URL`
     */
    protected get getBaseURL(): string {
        return this._baseURL
    }

    /**
     * Use to get `base URL V2`
     */
    protected get getBaseURLV2(): string {
        return this._baseURL_V2
    }

    /**
     * Use to show default toast
     * @param code
     * @param message
     * @param showDefaultToastEnabled
     */
    private _showToast(code: ResponseCode, message?: string, showDefaultToastEnabled: boolean = false) {
        const upgradePlanCodes = [
            ResponseCode.SEARCH_MORE_LIMITED_ERR,
            ResponseCode.UPGRADE_PLAN_REQUIRED_ERR,
            ResponseCode.UNLIMITED_SUBSCRIPTION_REQUIRED_ERR
        ]

        if (code == ResponseCode.RATE_LIMIT_EXCEEDED_ERR) {
            toast(
                { message: message || getCodeMessage(code) || 'An error occurred', type: 'error' },
                { duration: 10000 }
            )
        } else if (upgradePlanCodes.includes(code) && !showDefaultToastEnabled) {
            toast(
                {
                    message: getCodeMessage(code),
                    type: 'info',
                    icon: false,
                    sx: { fontSize: 25, width: { xs: '100%', md: 500 } }
                },
                { id: 'upgrade-plan-notice', duration: 10000, position: 'top-center' }
            )
        } else if (!showDefaultToastEnabled) {
            toast(
                { message: getCodeMessage(code) || message || 'An error occurred', type: 'error' },
                { duration: 10000 }
            )
        }
    }

    /**
     * Use to handle all requests catchs
     */
    private _handleError(error: any, options?: ApiRequestOptions) {
        const isAxiosErr = error instanceof AxiosError

        if (isAxiosErr) {
            const err = error as AxiosError<ResponseDetails>

            if (err.response?.data?.status == 'nok') {
                const code = err.response?.data?.code as ResponseCode
                const message = err.response?.data?.message
                const errors = err.response.data?.errors

                if (err.response?.status == 401) this._logout()

                if (errors && errors.length > 0)
                    toast({
                        message: `Please resolve validation errors:\n\n`.concat(
                            errors.map((item, index) => `${index + 1}. ${Object.values(item).at(0)}`).join('\n')
                        ),
                        color: 'error'
                    })

                if (!errors) this._showToast(code, message, options?.hideDefaultToast)
            }
        }

        return null
    }

    /**
     * Use to generate extra headers
     *
     * @param extraHeaders set if there is any new header
     */
    private _requestHeaderGenerator(extraHeaders?: RawAxiosRequestHeaders): RawAxiosRequestHeaders {
        const defaultHeaders: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }

        const isAuthenticated = Boolean(localStorage.getItem(authConfigs.storageTokenKeyName))

        if (isAuthenticated) {
            const token = localStorage.getItem(authConfigs.storageTokenKeyName) as string
            Object.assign(defaultHeaders, { authorization: `Bearer ${token}` })
        }

        const finalHeaders: RawAxiosRequestHeaders = {
            ...defaultHeaders,
            ...extraHeaders
        }

        return finalHeaders
    }

    /**
     * Use to redirect user to authentication page on 401 http code conditions
     */
    private _logout() {
        const userConfigs = localStorage.getItem(authConfigs.storageUserKeyName)

        if (userConfigs) {
            const redirectUrl = '/auth/signin?returnUrl='.concat(window.location.pathname)

            store.dispatch(logout())
            window.location.replace(redirectUrl)

            return
        }
    }

    /**
     * Use to send a `GET` request method
     * @param url request url
     */
    protected async get<T>(url: string, options?: ApiRequestOptions): Promise<ResponseDetails<T> | null | void> {
        try {
            const headers = this._requestHeaderGenerator(options?.headers)
            const response: AxiosResponse<ResponseDetails<T>> = await axios.get(url, {
                timeout: 60000,
                ...options,
                headers
            })

            const { code, status } = response?.data

            if (status == 'nok') this._showToast(code, undefined, options?.hideDefaultToast)

            return response.data
        } catch (err) {
            return this._handleError(err, options)
        }
    }

    /**
     * Use to send a `POST` request method
     * @param url request url
     * @param data request data
     */
    protected async post<T>(
        url: string,
        data: any,
        options?: ApiRequestOptions
    ): Promise<ResponseDetails<T> | null | void> {
        try {
            const headers = this._requestHeaderGenerator(options?.headers)
            const response: AxiosResponse<ResponseDetails<T>> = await axios.post(url, data, {
                timeout: 60000,
                ...options,
                headers
            })

            const { code, status } = response?.data

            if (status == 'nok') this._showToast(code, undefined, options?.hideDefaultToast)

            return response.data
        } catch (err) {
            return this._handleError(err, options)
        }
    }

    /**
     * Use to send a `PUT` request method
     * @param url request url
     * @param data request data
     */
    protected async put<T>(
        url: string,
        data: any,
        options?: ApiRequestOptions
    ): Promise<ResponseDetails<T> | null | void> {
        try {
            const headers = this._requestHeaderGenerator(options?.headers)
            const response: AxiosResponse<ResponseDetails<T>> = await axios.put(url, data, {
                timeout: 60000,
                ...options,
                headers
            })

            const { code, status } = response?.data

            if (status == 'nok') this._showToast(code, undefined, options?.hideDefaultToast)

            return response.data
        } catch (err) {
            return this._handleError(err, options)
        }
    }

    /**
     * Use to send a `PATCH` request method
     * @param url request url
     * @param data request data
     */
    protected async patch<T>(
        url: string,
        data: any,
        options?: ApiRequestOptions
    ): Promise<ResponseDetails<T> | null | void> {
        try {
            const headers = this._requestHeaderGenerator(options?.headers)
            const response: AxiosResponse<ResponseDetails<T>> = await axios.patch(url, data, {
                timeout: 60000,
                ...options,
                headers
            })

            const { code, status } = response?.data

            if (status == 'nok') this._showToast(code, undefined, options?.hideDefaultToast)

            return response.data
        } catch (err) {
            return this._handleError(err, options)
        }
    }

    /**
     * Use to send a `DELETE` request method
     * @param url request url
     */
    protected async delete<T>(url: string, options?: ApiRequestOptions): Promise<ResponseDetails<T> | null | void> {
        try {
            const headers = this._requestHeaderGenerator(options?.headers)
            const response: AxiosResponse<ResponseDetails<T>> = await axios.delete(url, {
                timeout: 60000,
                ...options,
                headers
            })

            const { code, status } = response?.data

            if (status == 'nok') this._showToast(code, undefined, options?.hideDefaultToast)

            return response.data
        } catch (err) {
            return this._handleError(err, options)
        }
    }
}
