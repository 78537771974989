// ** typedjson imports
import { jsonObject, jsonMember } from 'typedjson'

@jsonObject
export class IndustryModel {
    @jsonMember(String)
    name: string

    @jsonMember(String)
    title: string
}
