// ** react imports
import { useEffect, FC, PropsWithChildren } from 'react'

// ** react router imports
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

// ** redux import
import { useDispatch, useSelector } from 'react-redux'

// ** store imports
import type { AppDispatch, RootState } from 'src/store'
import { setUser } from 'src/store/features/auth'

// ** custom components imports
import Spinner from '../spinner'

// ** models imports
import { UserModel } from 'src/models'

// ** configs imports
import authConfigs from 'src/configs/auth'

const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
    // ** hooks
    const { loading, auth } = useSelector((selector: RootState) => selector.auth)
    const dispatch = useDispatch<AppDispatch>()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const token = window.localStorage.getItem(authConfigs.storageTokenKeyName)

        if (auth.user === null && !token) {
            if (pathname !== '/') {
                navigate(
                    {
                        pathname: '/auth/signin',
                        ...(!pathname.startsWith('/error') && {
                            search: createSearchParams({ returnUrl: pathname }).toString()
                        })
                    },
                    { replace: true }
                )

                return
            }
            navigate('/auth/signin', { replace: true })
        } else if (token) {
            const requiredKeys: Array<keyof UserModel> = [
                'email',
                'firstName',
                'lastName',
                'token',
                'created_at',
                'lead_plan',
                'enrichment_plan',
                'intercom_hash',
                'api_key',
                'analytics_user_id',
                'permissions'
            ]
            const stringifiedUser = window.localStorage.getItem(authConfigs.storageUserKeyName)
            const user: Omit<UserModel, 'token'> = JSON.parse(stringifiedUser as string)

            const userDataKeys = Object.keys(user)
            const notExistingKeys = requiredKeys.filter((item) => !userDataKeys.includes(item))

            if (notExistingKeys.length > 0) {
                window.localStorage.removeItem(authConfigs.storageTokenKeyName)
                window.localStorage.removeItem(authConfigs.storageUserKeyName)

                navigate('/auth/signin', { replace: true })

                return
            }

            dispatch(setUser({ token, ...user }))
        }
    }, [location])

    if (loading || auth.user === null) {
        return <Spinner />
    }

    return <>{children}</>
}

export default AuthGuard
