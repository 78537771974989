// ** configs imports
import keys from 'src/configs/auth'

type GTMMetaProps = {
    [x: string]: any
}

type GTMEventParams = {
    event: string
    props?: GTMMetaProps
}

export const sendGtmEvent = async (params: GTMEventParams) => {
    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID
    const gtmApiSecret = process.env.REACT_APP_GTM_API_SECRET
    const encodedUser = localStorage.getItem(keys.storageUserKeyName) || 'null'
    const decodedUser = JSON.parse(encodedUser)
    const userId = decodedUser?.analytics_user_id || null

    const gtagClientIdFetcher: Promise<string> = new Promise((resolve) => {
        ;(window as any).gtag('get', measurementId, 'client_id', resolve)
    })

    const gtagSessionIdFetcher: Promise<string> = new Promise((resolve) => {
        ;(window as any).gtag('get', measurementId, 'session_id', resolve)
    })

    const clientId = await gtagClientIdFetcher
    const sessionId = await gtagSessionIdFetcher
    const timestampMicro = +new Date() * 1000

    fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}&api_secret=${gtmApiSecret}`, {
        method: 'POST',
        body: JSON.stringify({
            client_id: clientId || 'not set',
            ...(userId && { user_id: userId }),
            timestamp_micros: timestampMicro,
            non_personalized_ads: false,
            events: [
                {
                    name: params.event,
                    params: { ...params.props, session_id: +sessionId }
                }
            ]
        })
    })
}
