// ** react imports
import { Suspense, lazy } from 'react'

// ** react router imports
import { RouteObject } from 'react-router-dom'

// ** custom components imports
import Spinner from 'src/@core/components/spinner'

// ** views imports
const UnauthorizedPage = lazy(() => import('src/pages/errors/unauthorized'))
const ForbiddenPage = lazy(() => import('src/pages/errors/forbidden'))
const NotFoundPage = lazy(() => import('src/pages/errors/not-found'))
const InternalErrorPage = lazy(() => import('src/pages/errors/internal-error'))

const errorRoutes: Array<RouteObject> = [
    {
        id: 'unauthorized-error-screen',
        path: '401',
        element: (
            <Suspense fallback={<Spinner />}>
                <UnauthorizedPage />
            </Suspense>
        )
    },
    {
        id: 'fobidden-error-screen',
        path: '403',
        element: (
            <Suspense fallback={<Spinner />}>
                <ForbiddenPage />
            </Suspense>
        )
    },
    {
        id: 'not-found-error-screen',
        path: '404',
        element: (
            <Suspense fallback={<Spinner />}>
                <NotFoundPage />
            </Suspense>
        )
    },
    {
        id: 'internal-error-screen',
        path: '500',
        element: (
            <Suspense fallback={<Spinner />}>
                <InternalErrorPage />
            </Suspense>
        )
    }
]

export default errorRoutes
