// ** react imports
import { useRef, useState } from 'react'

// ** mui imports
import Box from '@mui/material/Box'

// ** custom components imports
import Button from 'src/components/Button'

// ** third party imports
import { Icon } from '@iconify/react'

// ** types imports
import { HorizontalNavItemsType } from 'src/@core/layouts/types'

// ** configs imports
import themeConfig from 'src/configs/themeConfig'

// ** layout imports
import HorizontalNavItems from './HorizontalNavItems'

// ** Types
interface Props {
    horizontalNavItems?: HorizontalNavItemsType
}
let scroll = 0

const Navigation = (props: Props) => {
    // ** states
    const [canBack, setCanBack] = useState(false)
    const [canGo, setCanGo] = useState(true)

    // ** hooks
    const menuContainerRef = useRef<HTMLElement>(null)

    // ** methods
    const scrollBack = () => {
        const { scrollLeft } = menuContainerRef.current as HTMLElement

        if (scrollLeft > 0) {
            setCanGo(true)

            if (scrollLeft > 200) scroll -= 200
            else {
                scroll -= scrollLeft
                setCanBack(false)
            }
            menuContainerRef.current?.scrollTo({ behavior: 'smooth', top: 0, left: scroll })
        }

        if (menuContainerRef.current?.scrollLeft === 0) setCanBack(false)
    }

    const scrollNext = () => {
        const { scrollLeft, clientWidth, scrollWidth: maxWidth } = menuContainerRef.current as HTMLElement
        const scrolledWidth = clientWidth + scrollLeft

        if (scrolledWidth < maxWidth) {
            setCanBack(true)

            if (maxWidth - scrolledWidth > 200) scroll += 200
            else {
                scroll += maxWidth - scrolledWidth
                setCanGo(false)
            }
            menuContainerRef.current?.scrollTo({ behavior: 'smooth', top: 0, left: scroll })
        }

        if (scrolledWidth === maxWidth) setCanGo(false)
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'auto', px: 5 }}>
            <Button
                variant='text'
                size='small'
                disableRipple
                disableFocusRipple
                disableTouchRipple
                disabled={!canBack}
                startIcon={<Icon icon='material-symbols:chevron-left-rounded' style={{ fontSize: 25 }} />}
                onClick={scrollBack}
                sx={{ display: { xs: 'block', xxl: 'none' } }}
            />
            <Box
                ref={menuContainerRef}
                className='menu-content'
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                    overflowX: 'auto',
                    '& > *': {
                        '&:not(:last-child)': { mr: 2 },
                        ...(themeConfig.menuTextTruncate && { maxWidth: 220 })
                    }
                }}
                onScroll={({ currentTarget: ct }) => {
                    const { scrollWidth } = menuContainerRef.current as HTMLElement

                    if (ct.scrollLeft + ct.clientWidth == scrollWidth) {
                        !canBack && setCanBack(true)
                        setCanGo(false)
                    } else setCanGo(true)

                    if (ct.scrollLeft == 0) {
                        !canGo && setCanGo(true)
                        setCanBack(false)
                    } else setCanBack(true)
                }}
            >
                <HorizontalNavItems {...props} />
            </Box>
            <Button
                variant='text'
                size='small'
                disableRipple
                disableFocusRipple
                disableTouchRipple
                disabled={!canGo}
                endIcon={<Icon icon='material-symbols:chevron-right-rounded' style={{ fontSize: 25 }} />}
                onClick={scrollNext}
                sx={{ display: { xs: 'block', xxl: 'none' } }}
            />
        </Box>
    )
}

export default Navigation
