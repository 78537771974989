// ** typedjson imports
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class DomainSEOModel {
    @jsonMember(String)
    domain: string

    @jsonMember(Number)
    google_link_num_site: number | null

    @jsonMember(Number)
    harmonic_position: number | null

    @jsonMember(Number)
    harmonic_value: number | null

    @jsonMember(Number)
    number_of_domains: number | null

    @jsonMember(Number)
    pagerank_position: number | null

    @jsonMember(Number)
    pagerank_value: number | null
}
