// ** react imports
import { useState, SyntheticEvent, Fragment, ReactNode, useEffect, useMemo } from 'react'

// ** contexts imports
import { Settings } from 'src/@core/context/settingsContext'

// ** socket imports
import * as notificationsSocket from 'src/services/socket/notifications.socket'

// ** mui imports
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled, Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiMenu, { MenuProps } from '@mui/material/Menu'
import MuiMenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import Typography, { TypographyProps } from '@mui/material/Typography'

// ** custom components imports
import Icon from 'src/@core/components/icon'
import CustomChip from 'src/@core/components/mui/chip'
import Modal from 'src/components/Modal'

// ** third party imports
import moment from 'moment'

// ** models imports
import { NotificationModel } from 'src/models'

// ** utils imports
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'

interface NotificationsDropdownProps {
    settings: Settings
}

// ** Styled Menu component
const Menu = styled(MuiMenu)<MenuProps>(({ theme }) => ({
    '& .MuiMenu-paper': {
        width: 380,
        overflow: 'hidden',
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    '& .MuiMenu-list': {
        padding: 0
    }
}))

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)<MenuItemProps>(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
    }
}))

// ** Styled component for the title in MenuItems
const MenuItemTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontWeight: 500,
    flex: '1 1 100%',
    overflow: 'hidden',
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(0.75)
}))

const ScrollWrapper = ({ children }: { children: ReactNode; hidden: boolean }) => (
    <Box className='custom-scroll' sx={{ maxHeight: 250, overflowY: 'auto', overflowX: 'hidden' }}>
        {children}
    </Box>
)

const NotificationDropdown = (props: NotificationsDropdownProps) => {
    // ** states
    const [notifications, setNotifications] = useState<Array<NotificationModel>>([])
    const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)
    const [openNotificationInfoModal, setOpenNotificationInfoModal] = useState<{
        open: boolean
        notification: NotificationModel | null
    }>({ open: false, notification: null })

    // ** hooks
    const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

    useEffect(() => {
        notificationsSocket.connect().subscribe((data) => {
            setNotifications(data)
        })

        return () => {
            notificationsSocket.disconnect()
        }
    }, [])

    // ** methods
    const newNotifications = useMemo(() => {
        const newNotifs = notifications.filter((item) => item.is_new).length

        return newNotifs
    }, [notifications])

    const renderNotifInfoActions = () => (
        <Button variant='contained' onClick={handleCloseNotificationInfoModal}>
            Got it
        </Button>
    )

    const handleDropdownOpen = (event: SyntheticEvent) => {
        setAnchorEl(event.currentTarget)
    }

    const handleDropdownClose = () => {
        setAnchorEl(null)
    }

    const handleOpenNotificationInfoModal = (notificationId: number) => () => {
        const notif = notifications.filter((item) => item.id == notificationId).at(0)!
        notificationsSocket.readNotification(notificationId)

        setOpenNotificationInfoModal({ open: true, notification: notif })
    }

    const handleCloseNotificationInfoModal = () => {
        setOpenNotificationInfoModal({ open: false, notification: null })
    }

    const handleReadAllNotifications = () => {
        notificationsSocket.readAllNotifications()
        handleDropdownClose()
    }

    return (
        <Fragment>
            <IconButton
                color='inherit'
                aria-haspopup='true'
                onClick={handleDropdownOpen}
                aria-controls='customized-menu'
            >
                <Badge
                    color='error'
                    variant='dot'
                    invisible={newNotifications == 0}
                    sx={{
                        '& .MuiBadge-badge': {
                            top: 4,
                            right: 4,
                            boxShadow: (theme) => `0 0 0 2px ${theme.palette.background.paper}`
                        }
                    }}
                >
                    <Icon icon='mdi:bell-outline' />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDropdownClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: props.settings.direction === 'ltr' ? 'right' : 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: props.settings.direction === 'ltr' ? 'right' : 'left' }}
            >
                <MenuItem
                    disableRipple
                    disableTouchRipple
                    sx={{ cursor: 'default', userSelect: 'auto', backgroundColor: 'transparent !important' }}
                >
                    <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
                        <Typography fontWeight={600}>Notifications</Typography>
                        {newNotifications > 0 ? (
                            <CustomChip
                                skin='light'
                                size='small'
                                color='primary'
                                label={`${newNotifications} New`}
                                sx={{ height: 20, fontSize: '0.75rem', fontWeight: 500, borderRadius: '10px' }}
                            />
                        ) : null}
                    </Stack>
                </MenuItem>
                <ScrollWrapper hidden={hidden}>
                    {notifications.map((notification) => (
                        <MenuItem
                            key={notification.date.toString()}
                            onClick={handleOpenNotificationInfoModal(notification.id)}
                            sx={{
                                ...(notification.is_new && {
                                    bgcolor: ({ palette }) => hexToRGBA(palette.primary.main, 0.1)
                                })
                            }}
                        >
                            <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
                                <Stack direction='row' justifyContent='flex-start' alignItems='center' gap={3}>
                                    {notification.is_new ? <Badge color='primary' variant='dot' /> : null}
                                    <MenuItemTitle>{notification.title}</MenuItemTitle>
                                </Stack>

                                <Typography variant='caption' color='text.disabled'>
                                    {moment(notification.date).format('DD MMM')}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    ))}
                    {notifications.length == 0 ? (
                        <MenuItem disableRipple>
                            <Typography textAlign='center' width='100%'>
                                No notifications yet
                            </Typography>
                        </MenuItem>
                    ) : null}
                </ScrollWrapper>
                <MenuItem
                    disableRipple
                    disableTouchRipple
                    sx={{
                        py: 3.5,
                        borderBottom: 0,
                        cursor: 'default',
                        userSelect: 'auto',
                        backgroundColor: 'transparent !important',
                        borderTop: (theme) => `1px solid ${theme.palette.divider}`
                    }}
                >
                    <Button size='small' fullWidth variant='contained' onClick={handleReadAllNotifications}>
                        Read All Notifications
                    </Button>
                </MenuItem>
            </Menu>

            {/* BEGIN: notification info modal */}
            <Modal
                open={openNotificationInfoModal.open}
                modalTitle={openNotificationInfoModal.notification?.title}
                fullWidth
                maxWidth='sm'
                actions={renderNotifInfoActions()}
                onClose={handleCloseNotificationInfoModal}
            >
                <Typography>{openNotificationInfoModal.notification?.message}</Typography>
            </Modal>
            {/* END: notification info modal */}
        </Fragment>
    )
}

export default NotificationDropdown
