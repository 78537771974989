// ** mui imports
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

// ** custom components imports
import Image from 'src/components/Image'

const FallbackSpinner = () => {
    return (
        <Stack height='100vh' alignItems='center' justifyContent='center'>
            <Image src='/images/logo.svg' alt='CUFinder' sx={{ width: '120px', height: '30px' }} />
            <CircularProgress disableShrink sx={{ mt: 6 }} />
        </Stack>
    )
}

export default FallbackSpinner
