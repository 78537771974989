// ** third party imports
import * as XLSX from 'xlsx'

// ** utils
import { toast } from './toast'

type SheetOptions = {
    workSheetData: Array<any>
    name?: string
}

type DownloadSheetParams = {
    fileName: string
    sheetsOptions: Array<SheetOptions>
}

export const downloadSheet = (params: DownloadSheetParams) => {
    try {
        const workbook = XLSX.utils.book_new()

        for (const sheet of params.sheetsOptions) {
            const worksheet = XLSX.utils.aoa_to_sheet(sheet.workSheetData)

            XLSX.utils.book_append_sheet(workbook, worksheet, sheet?.name || 'Sheet1')
        }

        XLSX.writeFile(workbook, params.fileName)

        toast({ message: 'Your sheet downloaded successfully', severity: 'success' })
    } catch (err: any) {
        const message = err?.message || 'An error occurred while downloading the file'

        toast({ message, severity: 'error' })
    }
}
