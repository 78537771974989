// ** typedjson imports
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class UserLogModel {
    @jsonMember(Number)
    id: number

    @jsonMember(String)
    email: string

    @jsonMember(String)
    event: string

    @jsonMember(String)
    meta_data: string

    @jsonMember(Date)
    created_at: Date
}
