// ** third party imports
import { ReportCallback } from 'web-vitals'
import { onCLS, onFID, onFCP, onLCP, onTTFB } from 'web-vitals/attribution'

const reportWebVitals = (onPerfEntry?: ReportCallback) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        onCLS(onPerfEntry)
        onFID(onPerfEntry)
        onFCP(onPerfEntry)
        onLCP(onPerfEntry)
        onTTFB(onPerfEntry)
    }
}

export default reportWebVitals
